import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Line, Pie } from 'react-chartjs-2'
import uuid from 'node-uuid'
import moment from 'moment'
import { CardTurboModal } from '../../modals'
import * as actions from '../../../../RigsData/actions'
import * as miningPresetActions from '../../../../MiningPresets/actions'
import { useTranslation } from 'react-i18next'
import MobileCard from './MobileCard'

///////////////////////////////////////////// hashList need move to props
const hashList = ({ averageHashList = [], cName, cMem }) =>
  averageHashList.find(vcard => {
    if (cName.includes('super')) {
      if (cName.includes('1660') && vcard.cardName === '1660 S') {
        return vcard.cardName
      } else if (cName.includes('2060') && vcard.cardName === '2060 S') {
        return vcard.cardName
      } else if (cName.includes('2070') && vcard.cardName === '2070 S') {
        return vcard.cardName
      } else if (cName.includes('2080') && vcard.cardName === '2080 S') {
        return vcard.cardName
      }
    } else if (cName.includes('ti')) {
      if (cName.includes('1070') && vcard.cardName === '1070 Ti') {
        return vcard.cardName
      } else if (cName.includes('1080') && vcard.cardName === '1080 Ti') {
        return vcard.cardName
      } else if (cName.includes('1660') && vcard.cardName === '1660 Ti') {
        return vcard.cardName
      } else if (cName.includes('2080') && vcard.cardName === '2080 Ti') {
        return vcard.cardName
      } else if (cName.includes('3060') && vcard.cardName === '3060 Ti') {
        return vcard.cardName
      } else if (cName.includes('3070') && vcard.cardName === '3070 Ti') {
        return vcard.cardName
      } else if (cName.includes('3080') && vcard.cardName === '3080 Ti') {
        return vcard.cardName
      }
    } else if (cName.includes('lhr')) {
      if (cName.includes('3060') && vcard.cardName === '3060 LHR') {
        return vcard.cardName
      } else if (cName.includes('3070') && vcard.cardName === '3070 LHR') {
        return vcard.cardName
      } else if (cName.includes('3080') && vcard.cardName === '3080 LHR') {
        return vcard.cardName
      }
    } else if (cName.includes('470') && cMem.includes('8192')) {
      if (vcard.cardName === '470 8GB') return vcard.cardName
    } else if (cName.includes('570') && cMem.includes('8192')) {
      if (vcard.cardName === '570 8GB') return vcard.cardName
    } else {
      return cName.includes(vcard.cardName.toLowerCase())
    }
    return vcard
  })

////////////////////////////////////////////////////////////////////////////

const Statistics = props => {
  useEffect(() => {
    props.getRig({ rigId: props.newWorker.id })
  }, [])
  //////////////////////////////// check screen size
  const [width, setWidth] = useState(document.body.offsetWidth)
  const { t } = useTranslation('common')
  const handleWindowSizeChange = () => {
    setWidth(document.body.offsetWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const isMobile = width <= 767

  useEffect(() => {
    if (props.newWorker.id) {
      const rigStats = setInterval(() => {
        props.getRig({ rigId: props.newWorker.id, type: '5sec' })
      }, 10000)

      return () => {
        clearInterval(rigStats)
      }
    }
  }, [])
  ////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////// Chart Line Data Set

  const getChartLineData = useCallback(
    ({ time, hashrate, gpuOn, gpuOff, consumption, rigReboot, rigReload, rigCrash, rigTemp, rigFan, efficiency }) => {
      const chartLineItems = ({
        hashrate,
        gpuOn,
        gpuOff,
        consumption,
        rigReboot,
        rigReload,
        rigCrash,
        rigTemp,
        rigFan,
        efficiency,
      }) => {
        if (isMobile) {
          return [
            {
              label: t('pages.workers.hashrate'),
              data: hashrate,
              color: '#265ef1',
              axis: 'l',
            },
            {
              label: t('pages.workers.consumption'),
              data: consumption,
              color: '#f5f21a',
              axis: 'l',
            },
            {
              label: t('pages.workers.workerTemp'),
              data: rigTemp,
              color: '#149c2b',
              axis: 'l',
            },
            {
              label: t('pages.workers.workerFAN'),
              data: rigFan,
              color: '#a32bff',
              axis: 'l',
            },
          ]
        } else {
          return [
            {
              label: t('pages.workers.hashrate'),
              data: hashrate,
              color: '#265ef1',
              axis: 'l',
            },
            {
              label: t('pages.workers.GPUOnline'),
              data: gpuOn,
              color: '#00ff7f',
              axis: 'r',
            },
            {
              label: t('pages.workers.GPUOffline'),
              data: gpuOff,
              color: '#f60000',
              axis: 'r',
            },
            {
              label: t('pages.workers.consumption'),
              data: consumption,
              color: '#f5f21a',
              axis: 'l',
            },
            {
              label: t('pages.workers.workerReboots'),
              data: rigReboot,
              color: '#800000',
              axis: 'l',
            },
            {
              label: t('pages.workers.minerReload'),
              data: rigReload,
              color: '#dcdcdc',
              axis: 'l',
            },
            {
              label: t('pages.workers.minerCrashed'),
              data: rigCrash,
              color: '#ff8c00',
              axis: 'l',
            },
            {
              label: t('pages.workers.workerTemp'),
              data: rigTemp,
              color: '#149c2b',
              axis: 'l',
            },
            {
              label: t('pages.workers.workerFAN'),
              data: rigFan,
              color: '#a32bff',
              axis: 'l',
            },
            {
              label: t('pages.workers.efficiency'),
              data: efficiency,
              color: '#626ed4',
              axis: 'l',
            },
          ]
        }
      }
      return {
        labels: time,
        datasets: chartLineItems({
          hashrate,
          gpuOn,
          gpuOff,
          consumption,
          rigReboot,
          rigReload,
          rigCrash,
          rigTemp,
          rigFan,
          efficiency,
        }).map(item => {
          return {
            label: item.label,
            data: item.data,
            backgroundColor: item.color,
            borderWidth: 1,
            borderColor: item.color,
            fill: false,
            lineTension: 0.4,
            radius: 0,
            hoverRadius: 1,
            yAxisID: item.axis ? item.axis : '',
          }
        }),
      }
    },
    [isMobile, t],
  )

  const [isCardTurboModal, setIsCardTurboModal] = useState(false)
  const [isLocalIpCopied, setIsLocalIpCopied] = useState(false)
  const [isMACCopied, setIsMACCopied] = useState(false)
  const [showAlertTurnOff, setShowAlertTurnOff] = useState(false)
  const [showAlertStart, setShowAlertStart] = useState(false)
  const [showAlertReboot, setShowAlertReboot] = useState(false)
  const [showAlertHardReboot, setShowAlertHardReboot] = useState(false)

  const [showAlertPause, setShowAlertPause] = useState(false)
  const [showAlertDelete, setShowAlertDelete] = useState(false)
  const [showAlertRedetect, setShowAlertRedetect] = useState(false)
  const [showAlertDeleteCard, setShowAlertDeleteCard] = useState(false)
  const [showAlertoffMiningCard, setShowAlertoffMiningCard] = useState(false)
  const [workerCards, setWorkerCards] = useState([])
  const [deleteCard, setDeleteCard] = useState()
  const [offMiningCard, setoffMiningCard] = useState({ card: {}, offMining: false })
  const setAverageHashList = props.setAverageHashList
  const nowDate = new Date().getTime()

  useEffect(() => {
    setAverageHashList({ page: 1, size: 100 })
  }, [setAverageHashList])

  const [chartPieData, setChartPieData] = useState([])

  const [rigStatsPeriod, setRigStatsPeriod] = useState('h')
  const [prevChartData, setPrevChartData] = useState({
    time: [],
    hashrate: [],
    gpuOn: [],
    gpuOff: [],
    consumption: [],
    rigReboot: [],
    rigReload: [],
    rigCrash: [],
    rigTemp: [],
    rigFan: [],
    efficiency: [],
  })
  const [chartLineData, setChartlineData] = useState({
    labels: [],
    datasets: [],
  })
  const [chartView, setChartView] = useState('')
  const [count, setCount] = useState(60000)

  const chartLineConfigMobile = useMemo(() => {
    return {
      maintainAspectRatio: false,
      responsive: true,
      borderWidth: 0,
      title: { display: false },
      tooltips: {
        enabled: true,
        caretSize: 2,
        titleFontSize: 12,
        bodyFontSize: 12,
        bodySpacing: 2,
        titleSpacing: 0,
        xPadding: 2,
        yPadding: 2,
        cornerRadius: 2,
        titleMarginBottom: 1,
        mode: 'index',
        position: 'nearest',
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const units = [t('pages.workers.MH'), t('pages.workers.W'), '℃', '%']
            const dataset = data.datasets[tooltipItem.datasetIndex]
            return (
              dataset.label +
              ': ' +
              dataset.data[tooltipItem.index] +
              ' ' +
              units[dataset._meta[Object.keys(dataset._meta)[0]].index]
            )
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 2,
          fontColor: '#e9ecef',
          fontSize: 10,
          padding: 4,
          usePointStyle: true,
        },
      },
      interaction: { mode: 'nearest', axis: 'x', intersect: false },
      hover: { mode: 'nearest', intersect: false, axis: 'x' },
      scales: {
        xAxes: [{ display: false }],
        yAxes: [
          {
            display: true,
            position: 'left',
            id: 'l',
            ticks: {
              fontSize: 9,
              fontColor: '#9ca8b3',
              beginAtZero: true,
            },
          },
          {
            display: true,
            position: 'right',
            id: 'r',
            ticks: {
              fontSize: 9,
              fontColor: '#9ca8b3',
              beginAtZero: true,
              max: workerCards.length,
              min: 0,
            },
          },
        ],
      },
    }
  }, [t, workerCards.length])

  const chartLineConfig = useMemo(() => {
    return {
      maintainAspectRatio: false,
      responsive: true,
      title: { display: false },
      borderWidth: 0,
      tooltips: {
        usePointStyle: true,
        enabled: true,
        caretSize: 4,
        titleFontSize: 10,
        bodyFontSize: 10,
        bodySpacing: 1,
        titleSpacing: 0,
        xPadding: 2,
        yPadding: 2,
        cornerRadius: 2,
        titleMarginBottom: 1,
        // mode: 'index',
        position: 'average',
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            const units = [
              t('pages.workers.MH'),
              '',
              '',
              t('pages.workers.W'),
              t('pages.workers.na'),
              t('pages.workers.na'),
              t('pages.workers.na'),
              '℃',
              '%',
              '%',
            ]
            const dataset = data.datasets[tooltipItem.datasetIndex]
            return (
              dataset.label +
              ': ' +
              dataset.data[tooltipItem.index] +
              ' ' +
              units[dataset._meta[Object.keys(dataset._meta)[0]].index]
            )
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 2,
          fontColor: '#e9ecef',
          fontSize: 9.5,
          padding: 4,
          usePointStyle: true,
        },
      },
      interaction: { mode: 'nearest', axis: 'x', intersect: false },
      hover: { mode: 'nearest', intersect: false, axis: 'x' },
      scales: {
        xAxes: [{ display: false }],
        yAxes: [
          {
            display: true,
            position: 'left',
            id: 'l',
            ticks: {
              beginAtZero: true,
              fontColor: '#9ca8b3',
            },
          },

          {
            display: true,
            position: 'right',
            id: 'r',
            ticks: {
              beginAtZero: true,
              fontColor: '#9ca8b3',
              max: workerCards.length,
            },
          },
        ],
      },
    }
  }, [t, workerCards.length])

  useEffect(() => {
    // const huy = (chartLineData.labels.length*10)
    setChartView(
      // <div style={{ width: '100%', overflowX: 'auto' }}>
      //   <div style={{ width: `${huy}` }}>
      //     {/* <div style={{ width: '600px' }}> */}
      //     <Line
      //       style={{ width: '0', height: '200px' }}
      //       data={props.worker.rigMac ? chartLineData : {}}
      //       options={isMobile ? chartLineConfigMobile : chartLineConfig}
      //     />
      //   </div>
      // </div>

      <Line
        data={props.worker.rigMac ? chartLineData : {}}
        options={isMobile ? chartLineConfigMobile : chartLineConfig}
      />,
    )
  }, [chartLineConfig, chartLineConfigMobile, chartLineData, isMobile, props.worker.rigMac])

  useEffect(() => {
    setPrevChartData({
      time: [],
      hashrate: [],
      gpuOn: [],
      gpuOff: [],
      consumption: [],
      rigReboot: [],
      rigReload: [],
      rigCrash: [],
      rigTemp: [],
      rigFan: [],
      efficiency: [],
    })
    setChartlineData({
      labels: [],
      datasets: [],
    })
  }, [])

  const setRigStats = props.setRigStats

  const updateStat = useCallback(
    (isLoadingNeeded = true) => {
      if (rigStatsPeriod === 'c' || rigStatsPeriod === 'current') {
        setPrevChartData({
          time: [],
          hashrate: [],
          gpuOn: [],
          gpuOff: [],
          consumption: [],
          rigReboot: [],
          rigReload: [],
          rigCrash: [],
          rigTemp: [],
          rigFan: [],
          efficiency: [],
        })
        setChartlineData({
          labels: [],
          datasets: [],
        })
      }

      if ((rigStatsPeriod === 'h' || rigStatsPeriod === 'hour') && props.worker.id)
        setRigStats({
          rigId: props.worker.id,
          typeGraph: '1hr',
          isLoadingNeeded,
        })

      if ((rigStatsPeriod === 'd' || rigStatsPeriod === 'day') && props.worker.id)
        setRigStats({
          rigId: props.worker.id,
          typeGraph: 'day',
          isLoadingNeeded,
        })

      if ((rigStatsPeriod === 'w' || rigStatsPeriod === 'week') && props.worker.id)
        setRigStats({
          rigId: props.worker.id,
          typeGraph: 'week',
          isLoadingNeeded,
        })

      if ((rigStatsPeriod === 'm' || rigStatsPeriod === 'month') && props.worker.id)
        setRigStats({
          rigId: props.worker.id,
          typeGraph: 'month',
          isLoadingNeeded,
        })

      if ((rigStatsPeriod === 'q' || rigStatsPeriod === 'quarter') && props.worker.id)
        setRigStats({
          rigId: props.worker.id,
          typeGraph: 'quarter',
          isLoadingNeeded,
        })
    },
    [rigStatsPeriod, props.worker.id, setRigStats],
  )

  const detectedCard =
    props.wokrer && props.worker.videoCards ? props.worker.videoCards.filter(card => card.detected === true) : []

  const unDetectedCard =
    props.wokrer && props.worker.videoCards ? props.worker.videoCards.filter(card => card.detected === false) : []

  const now = useMemo(() => {
    return new Date()
  }, [])
  const chartLineZoom = period => setRigStatsPeriod(period)

  let totalPower = 0
  let totalEff = 0
  let totalEffDual = 0
  let totalTemp = 0
  let totalFan = 0

  useEffect(() => {
    updateStat()
    if (rigStatsPeriod === 'h' || rigStatsPeriod === 'hour') setCount(60000)
    if (rigStatsPeriod === 'd' || rigStatsPeriod === 'day') setCount(300000)
    if (rigStatsPeriod === 'w' || rigStatsPeriod === 'week') setCount(3600000)
    if (rigStatsPeriod === 'm' || rigStatsPeriod === 'month') setCount(10800000)
    if (rigStatsPeriod === 'q' || rigStatsPeriod === 'quarter') setCount(32400000)
  }, [rigStatsPeriod, props.worker.rigMac, updateStat])

  useEffect(() => {
    let statInterval = null
    if (rigStatsPeriod !== 'c' && rigStatsPeriod !== 'current') {
      statInterval = setInterval(() => updateStat(false), count)
    }
    return () => {
      clearInterval(statInterval)
    }
  }, [count, rigStatsPeriod, updateStat])

  useEffect(() => {
    if (rigStatsPeriod === 'c' || rigStatsPeriod === 'current') {
      const prevChartDataCopy = { ...prevChartData }

      prevChartDataCopy.time.push(now.toLocaleString())
      prevChartDataCopy.hashrate.push(
        props.worker.active && props.worker.gpuTotalSpeed > 0 ? props.worker.gpuTotalSpeed : 0,
      )
      prevChartDataCopy.gpuOn.push(props.worker.active ? detectedCard.length : 0)
      prevChartDataCopy.gpuOff.push(props.worker.active ? unDetectedCard.length : workerCards.length)
      prevChartDataCopy.consumption.push(props.worker.powCons > 0 ? props.worker.powCons : 0)
      prevChartDataCopy.rigReboot.push(props.worker.rebootNum > 0 ? props.worker.rebootNum : 0)
      prevChartDataCopy.rigReload.push(props.worker.reloadNum > 0 ? props.worker.reloadNum : 0)
      prevChartDataCopy.rigCrash.push(props.worker.crashNum > 0 ? props.worker.crashNum : 0)
      prevChartDataCopy.rigTemp.push(totalTemp > 0 ? totalTemp.toFixed() : 0)
      prevChartDataCopy.rigFan.push(totalFan > 0 ? totalFan.toFixed() : 0)
      prevChartDataCopy.efficiency.push(props.worker.active && totalEff > 0 ? totalEff : 0)

      const {
        time,
        hashrate,
        gpuOn,
        gpuOff,
        consumption,
        rigReboot,
        rigReload,
        rigCrash,
        rigTemp,
        rigFan,
        efficiency,
      } = prevChartDataCopy

      const newChartLineData = getChartLineData({
        time,
        hashrate,
        gpuOn,
        gpuOff,
        consumption,
        rigReboot,
        rigReload,
        rigCrash,
        rigTemp,
        rigFan,
        efficiency,
      })

      setChartlineData(newChartLineData)
      setPrevChartData(prevChartDataCopy)
    }
  }, [
    detectedCard.length,
    getChartLineData,
    now,
    prevChartData,
    props,
    rigStatsPeriod,
    totalEff,
    totalFan,
    totalTemp,
    unDetectedCard.length,
    workerCards.length,
  ])

  useEffect(() => {
    if (rigStatsPeriod !== 'c' && rigStatsPeriod !== 'current') {
      const time = []
      const hashrate = []
      const gpuOn = []
      const gpuOff = []
      const consumption = []
      const rigReboot = []
      const rigReload = []
      const rigCrash = []
      const rigTemp = []
      const rigFan = []
      const efficiency = []
      const uptimeData = []
      const downtimeData = []

      for (const item of props.rigStatList) {
        let effChartTotal = 0

        const totalEffFull = (100 - (+item.gpu_rej / (+item.gpu_total_shares + +item.gpu_rej)) * 100).toString()

        effChartTotal = totalEffFull !== '100' ? totalEffFull.slice(0, totalEffFull.indexOf('.') + 3) : totalEffFull

        time.push(moment(+item.updatedat).format('YYYY-MM-DD HH:mm'))
        hashrate.push(item.gpu_total_speed > 0 ? item.gpu_total_speed : 0)
        gpuOn.push(item.gpu_online > 0 ? item.gpu_online : 0)
        gpuOff.push(item.gpu_ofline > 0 ? item.gpu_ofline : 0)
        consumption.push(item.pow_cons > 0 ? item.pow_cons : 0)
        rigReboot.push(item.rebootNum > 0 ? item.rebootNum : 0)
        rigReload.push(item.reloadNum > 0 ? item.reloadNum : 0)
        rigCrash.push(item.crashNum > 0 ? item.crashNum : 0)
        rigTemp.push(item.rig_temp > 0 ? item.rig_temp : 0)
        rigFan.push(item.rig_fan > 0 ? item.rig_fan : 0)
        efficiency.push(effChartTotal > 0 ? effChartTotal : 0)
        uptimeData.push(item.uptime)
        downtimeData.push(item.downtime)
      }
      const newChartLineData = getChartLineData({
        time,
        hashrate,
        gpuOn,
        gpuOff,
        consumption,
        rigReboot,
        rigReload,
        rigCrash,
        rigTemp,
        rigFan,
        efficiency,
      })

      setChartlineData(newChartLineData)
      setPrevChartData({
        time,
        hashrate,
        gpuOn,
        gpuOff,
        consumption,
        rigReboot,
        rigReload,
        rigCrash,
        rigTemp,
        rigFan,
        efficiency,
      })

      setChartPieData([uptimeData.reduce((acc, num) => acc + num, 0), downtimeData.reduce((acc, num) => acc + num, 0)])
    }
  }, [getChartLineData, props.rigStatList, rigStatsPeriod])

  useEffect(() => {
    let hashRateState = 0
    if (props.worker.videoCards && props.worker.videoCards.length) {
      const newDetectedCard = props.worker.videoCards.map(item => {
        const cName = item.gpuName ? item.gpuName.toLowerCase() : ''
        const cMem = item.vram ? item.vram.toLowerCase() : ''
        const checkHash = hashList({
          averageHashList: props.averageHashList,
          cName,
          cMem,
        })

        if (checkHash && checkHash[`${props.worker.algo}`] && props.worker.algo) {
          hashRateState = 100 - (item.hashCard / checkHash[`${props.worker.algo}`]) * 100
        }
        if (checkHash && checkHash[`${props.worker.algo}`] && !props.worker.algo) {
          hashRateState = 100 - (item.hashCard / checkHash.ethash) * 100
        }
        if (!checkHash) {
          hashRateState = 0
        }

        const totalEffCard = item.blocksNumber
          ? props.worker.miner === 'nbminer'
            ? ((+item.blocksNumber - +item.badBlocsNumber / +item.blocksNumber) * 100).toString()
            : (
                ((+item.blocksNumber - +item.invalidShares - +item.badBlocsNumber) / +item.blocksNumber) *
                100
              ).toString()
          : '0'

        const totalEffCardDual = item.blocksNumberDual
          ? props.worker.miner === 'nbminer'
            ? (100 - (+item.badBlocsNumberDual / (+item.blocksNumberDual + +item.badBlocsNumberDual)) * 100).toString()
            : (
                100 -
                ((+item.invalidSharesDual + +item.badBlocsNumberDual) /
                  (+item.blocksNumberDual + +item.invalidSharesDual + +item.badBlocsNumberDual)) *
                  100
              ).toString()
          : '0'

        const cardEffiency =
          totalEffCard !== '100' || totalEffCard.includes('.')
            ? totalEffCard.slice(0, totalEffCard.indexOf('.') + 3)
            : totalEffCard

        const cardEffiencyDual =
          totalEffCardDual !== '100' || totalEffCardDual.includes('.')
            ? totalEffCardDual.slice(0, totalEffCardDual.indexOf('.') + 3)
            : totalEffCardDual

        const cardDetected = props.worker.active && item.detected ? true : false

        return {
          ...item,
          hashRateState,
          cardEffiency,
          cardEffiencyDual,
          cardDetected,
        }
      })
      setWorkerCards(newDetectedCard)
    }
  }, [props.worker.active, props.worker.miner, props.worker.algo, props.averageHashList, props.worker.videoCards])

  const chartLineZoomBtn = isMobile
    ? [
        //{value:'current', name: t('pages.workers.current'), tip: t('pages.workers.currentTip'),},
        { value: 'hour', name: t('pages.workers.hour'), tip: t('pages.workers.hourTip') },
        { value: 'day', name: t('pages.workers.day'), tip: t('pages.workers.dayTip') },
        { value: 'week', name: t('pages.workers.week'), tip: t('pages.workers.weekTip') },
        { value: 'month', name: t('pages.workers.month'), tip: t('pages.workers.monthTip') },
        { value: 'quarter', name: t('pages.workers.quarter'), tip: t('pages.workers.quarterTip') },
      ]
    : [
        //{value:'c', name: t('pages.workers.c'), tip: t('pages.workers.cTip') },
        { value: 'h', name: t('pages.workers.h'), tip: t('pages.workers.hTip') },
        { value: 'd', name: t('pages.workers.d'), tip: t('pages.workers.dTip') },
        { value: 'w', name: t('pages.workers.w'), tip: t('pages.workers.wTip') },
        { value: 'm', name: t('pages.workers.m'), tip: t('pages.workers.mTip') },
        { value: 'q', name: t('pages.workers.q'), tip: t('pages.workers.qTip') },
      ]

  ///////////////////////////////////////////////////////////////// calculate total cards data:
  const sharesSum = workerCards.reduce((totalGpu, item) => totalGpu + +item.blocksNumber, 0)
  const rejectesSum = workerCards.reduce((totalMem, item) => totalMem + +item.badBlocsNumber, 0)
  const invalidSum = workerCards.reduce((totalMem, item) => totalMem + +item.invalidShares, 0)

  const sharesSumDual = workerCards.reduce((totalGpu, item) => totalGpu + +item.blocksNumberDual, 0)
  const rejectesSumDual = workerCards.reduce((totalMem, item) => totalMem + +item.badBlocsNumberDual, 0)
  const invalidSumDual = workerCards.reduce((totalMem, item) => totalMem + +item.invalidSharesDual, 0)

  workerCards.map(card => {
    totalPower += +card.powerInfo
    totalTemp += +card.gpuTemp / workerCards.length
    totalFan += +card.fanInfo / workerCards.length

    const totalEffFull =
      props.worker.miner === 'nbminer'
        ? ((sharesSum - rejectesSum - invalidSum / sharesSum) * 100).toString()
        : (((sharesSum - rejectesSum - invalidSum) / sharesSum) * 100).toString()

    const totalEffFullDual =
      props.worker.miner === 'nbminer'
        ? (((sharesSumDual - rejectesSumDual - invalidSumDual) / sharesSumDual) * 100).toString()
        : (((sharesSumDual - rejectesSumDual - invalidSumDual) / sharesSumDual) * 100).toString()

    totalEff =
      totalEffFull !== '100' || totalEffFull.includes('.')
        ? totalEffFull.slice(0, totalEffFull.indexOf('.') + 3)
        : totalEffFull

    totalEffDual =
      totalEffFullDual !== '100' || totalEffFullDual.includes('.')
        ? totalEffFullDual.slice(0, totalEffFullDual.indexOf('.') + 3)
        : totalEffFullDual

    return card
  })

  /////////////////////////////////////////////////////////////////////////////// Chart Pie Data Set
  const data2 = {
    labels: [t('pages.workers.uptime'), t('pages.workers.downtime')],
    datasets: [
      {
        data: chartPieData,
        borderWidth: 0,
        backgroundColor: ['#626ed4', '#e4909c'],
        hoverBackgroundColor: ['#626ed4', '#e4909c'],
        hoverBorderColor: '#fff',
      },
    ],
  }

  const getStatusTimeInt = timeData => {
    const time = Math.abs(timeData * 60)
    let result, units
    if (time > 60 * 60 * 24) {
      result = parseInt(time / (60 * 60 * 24), 10)
      units = t('pages.workers.days')
    } else if (time > 60 * 60) {
      result = parseInt(time / (60 * 60), 10)
      units = t('pages.workers.hours')
    } else if (time > 60) {
      result = parseInt(time / 60, 10)
      units = t('pages.workers.minutes')
    } else {
      result = parseInt(time, 10)
      units = t('pages.workers.seconds')
    }
    return result + ' ' + units
  }

  const option2 = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      usePointStyle: true,
      enabled: true,
      caretSize: 4,
      titleFontSize: 10,
      bodyFontSize: 10,
      bodySpacing: 1,
      titleSpacing: 0,
      xPadding: 2,
      yPadding: 2,
      cornerRadius: 2,
      titleMarginBottom: 1,
      callbacks: {
        label: (tooltipItem, data) => {
          const dataset = data.datasets[tooltipItem.datasetIndex]
          const meta = dataset._meta[Object.keys(dataset._meta)[0]]
          const total = meta.total
          const currentValue = getStatusTimeInt(dataset.data[tooltipItem.index])
          const percentage = parseFloat(((dataset.data[tooltipItem.index] / total) * 100).toFixed(1))
          return currentValue + ' (' + percentage + '%)'
        },
        title: (tooltipItem, data) => {
          return data.labels[tooltipItem[0].index]
        },
      },
    },
    legend: {
      labels: {
        boxWidth: 2,
        fontSize: 9.5,
        fontColor: '#e9ecef',
        usePointStyle: true,
        padding: 4,
      },
      color: '#e9ecef',
    },
  }
  /////////////////////////////////////////////////////////////////////////////// END Chart Pie Data Set

  const rebootWorker = async workerID => {
    const reboot = {
      rigId: +workerID,
      powerOf: false,
      reboot: true,
      hardReboot: false,
    }
    await props.resetOrPowerOfWorker(reboot).then()
  }

  const hardRebootWorker = async workerID => {
    const reboot = {
      rigId: +workerID,
      powerOf: false,
      reboot: false,
      hardReboot: true,
    }
    await props.resetOrPowerOfWorker(reboot).then()
  }

  const turnOFFWorker = async workerID => {
    const reboot = {
      rigId: +workerID,
      powerOf: true,
      reboot: false,
      hardReboot: false,
    }
    await props.resetOrPowerOfWorker(reboot).then()
  }

  const turnOff = () => setShowAlertTurnOff(true)
  const start = () => setShowAlertStart(true)
  const pause = () => setShowAlertPause(true)
  const reboot = () => setShowAlertReboot(true)
  const hardReboot = () => setShowAlertHardReboot(true)
  const deleteWorker = () => setShowAlertDelete(true)
  const redetect = () => setShowAlertRedetect(true)

  const onHandleDeleteCard = async cardId => {
    const isDeleted = await props.deleteCard({ rigId: props.worker.id, cardId })
    isDeleted && setShowAlertDeleteCard(false)
    isDeleted && props.delayRigUpdate(true)
  }

  const onHandleoffMiningCard = async ({ obj }) => {
    await props.offMiningCard({ rigId: props.worker.id, cardId: +obj.card, offMining: obj.offMining })
    setShowAlertoffMiningCard(false)
  }

  const loadAverage = props.worker.average ? +props.worker.average.split(' ')[0] : ''

  const removeCard = card => {
    setShowAlertDeleteCard(true)
    setDeleteCard(card)
  }

  const offMining = (card, bool) => {
    setShowAlertoffMiningCard(true)
    setoffMiningCard({ card, offMining: bool })
  }

  const copyData = data => {
    if (data === props.worker.ip) setIsLocalIpCopied(true)
    if (data === props.worker.rigMac) setIsMACCopied(true)
    navigator.clipboard.writeText(data)
  }

  const totalHashRate = (hashRateValue, unit) => {
    if (hashRateValue < 1 && hashRateValue > 0) {
      if (unit === 'h/s') {
        unit = 'kH/s'
        hashRateValue = hashRateValue * 1000
      }
      if (unit === 'MH/s') {
        unit = 'kH/s'
      }
      if (unit === 'GH/s') {
        unit = 'GH/s'
        hashRateValue = hashRateValue / 1000
      }
      if (unit === 'ksol/s') unit = 'sol/s'
    }
    if (hashRateValue > 1000) {
      if (unit === 'h/s') unit = 'kH/s'
      else if (unit === 'kH/s') unit = 'MH/s'
      else if (unit === 'MH/s') unit = 'GH/s'
      else if (unit === 'ksol/s') unit = 'sol/s'
      hashRateValue = hashRateValue / 1000
    }
    return hashRateValue.toFixed(2) + ' ' + unit
  }

  return (
    <div>
      <div className={'alerts'}>
        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertTurnOff}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            turnOFFWorker(props.newWorker.id)
            setShowAlertTurnOff(false)
          }}
          onCancel={() => {
            setShowAlertTurnOff(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.workerOff')}
        </SweetAlert>
        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertStart}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigMinerAction({
              rigId: props.newWorker.id,
              action: 'run',
            })
            setShowAlertStart(false)
          }}
          onCancel={() => {
            setShowAlertStart(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.miningOn')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertPause}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigMinerAction({
              rigId: props.newWorker.id,
              action: 'stop',
            })
            setShowAlertPause(false)
          }}
          onCancel={() => {
            setShowAlertPause(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.miningOff')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertReboot}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            rebootWorker(props.newWorker.id)
            setShowAlertReboot(false)
          }}
          onCancel={() => {
            setShowAlertReboot(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.workerReboot')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertHardReboot}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            hardRebootWorker(props.newWorker.id)
            setShowAlertHardReboot(false)
          }}
          onCancel={() => {
            setShowAlertHardReboot(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.workerRebootHard')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertDelete}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.deleteRig({ rigId: props.newWorker.id })
            props.onCancel()
            props.clearRigFullInfo()
            setShowAlertDelete(false)
          }}
          onCancel={() => {
            props.onCancel()
            props.clearRigFullInfo()
            setShowAlertDelete(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.workerRemoved')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertDeleteCard}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            onHandleDeleteCard(deleteCard)
          }}
          onCancel={() => {
            setShowAlertDeleteCard(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.cardRemoved')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertoffMiningCard}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            onHandleoffMiningCard({ obj: offMiningCard })
          }}
          onCancel={() => {
            setShowAlertoffMiningCard(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {offMiningCard.offMining ? t('sweetAlerts.offMiningCard') : t('sweetAlerts.onMiningCard')}
        </SweetAlert>

        <SweetAlert
          title={t('sweetAlerts.areConfirm')}
          warning
          showCancel
          confirmBtnBsStyle='primary'
          cancelBtnBsStyle='secondary'
          show={showAlertRedetect}
          focusCancelBtn={true}
          focusConfirmBtn={false}
          confirmBtnStyle={{ border: 0, boxShadow: 'none' }}
          cancelBtnStyle={{
            borderColor: 'rgb(40, 96, 144)',
            boxShadow: 'rgb(0 0 0 / 8%) 0px 1px 1px inset, rgb(165 202 234) 0px 0px 8px',
          }}
          onConfirm={() => {
            props.setRigMinerAction({
              rigId: props.newWorker.id,
              redetect: true,
            })
            setShowAlertRedetect(false)
          }}
          onCancel={() => {
            setShowAlertRedetect(false)
          }}
          confirmBtnText={t('sweetAlerts.confirm')}
          cancelBtnText={t('sweetAlerts.cancel')}
          showCloseButton={true}
        >
          {t('sweetAlerts.redetect')}
        </SweetAlert>
      </div>

      <Row className={'chart-line-row'}>
        <Col className='chart-line'>{chartView}</Col>

        <div className='mobile-actionbox'>
          <div className='chart-zoom'>
            {chartLineZoomBtn.map(btn => {
              return (
                <button
                  className={`chart-zoom__btn ${rigStatsPeriod === btn.name ? 'chart-zoom__btn_active' : ''}`}
                  tooltip={btn.tip}
                  flow={'left'}
                  onClick={() => chartLineZoom(btn.value)}
                  key={uuid()}
                >
                  {btn.name}
                </button>
              )
            })}
          </div>

          <Col className='worker_chart_parent chart-pie'>
            <div className='chart-pie__pie'>
              <Pie data={data2} options={option2} />
            </div>
          </Col>

          <div className='action-items__box'>
            <div>
              {props.worker.action !== 'run' &&
                (props.worker.miningPresetId ? (
                  <div
                    className='actions__item actions__item_rig'
                    tooltip={t('pages.workers.startMining')}
                    flow={'left'}
                    onClick={() => start()}
                  >
                    <i className='fas fa-play'></i>
                  </div>
                ) : (
                  <div
                    className='actions__item actions__item_rig'
                    tooltip={t('pages.workers.assignPresetStartMining')}
                    flow={'left'}
                  >
                    <i className='fas fa-play'></i>
                  </div>
                ))}
              {props.worker.action === 'run' && (
                <div
                  className='actions__item actions__item_rig'
                  tooltip={t('pages.workers.stopMining')}
                  flow={'left'}
                  onClick={() => pause()}
                >
                  <i className='fas fa-stop'></i>
                </div>
              )}
              <div
                className='actions__item actions__item_rig'
                tooltip={t('pages.workers.reboot')}
                flow='left'
                onClick={() => reboot()}
              >
                <i className='fas fa-sync'></i>
              </div>
              {props.worker.videoCards && props.worker.videoCards.length > 0 && (
                <div
                  className={'actions__item actions__item_rig'}
                  tooltip={t('pages.workers.overclocking')}
                  flow='left'
                  onClick={() => setIsCardTurboModal(true)}
                >
                  <i className='fas fa-sliders-h'></i>
                </div>
              )}
              {isCardTurboModal && (
                <CardTurboModal
                  rigId={props.worker.id}
                  rigName={props.worker.name}
                  onCancel={() => setIsCardTurboModal(false)}
                />
              )}
              <div
                className='actions__item actions__item_rig'
                tooltip={t('pages.workers.redetect')}
                flow='left'
                onClick={() => redetect()}
              >
                <i className='fa fa-undo'></i>
              </div>
            </div>

            <div>
              <div
                className='actions__item actions__item_rig'
                tooltip={t('pages.workers.turnOff')}
                flow={'left'}
                onClick={() => turnOff()}
              >
                <i className='fas fa-power-off'></i>
              </div>
              <div
                className='actions__item actions__item_rig'
                tooltip={t('pages.workers.hardReboot')}
                flow='left'
                onClick={() => hardReboot()}
              >
                <i className='fas fa-exclamation'></i>
              </div>
              <div
                className='actions__item actions__item_rig actions__item_delete-rig'
                tooltip={t('pages.workers.deleteWorker')}
                flow={'left'}
                onClick={() => deleteWorker()}
              >
                <i className='fas fa-trash'></i>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row className='worker_charts'>
        <Col xs={12} className='worker-block_table'>
          <div className={'worker-cards-mobile d-md-none'}>
            {props.worker.videoCards &&
              workerCards.map((card, indx) => {
                if (indx === 0) {
                  props.worker.hashRateValue = 0
                  props.worker.hashRateValueDual = 0
                }

                card.hashCardString = 0
                if (card.detected) {
                  let hashRate = card.hashCard > 0 ? +card.hashCard : 0

                  let hashRateUnit = props.worker.unit ? props.worker.unit : 'MH/s'
                  while (hashRate > 1000) {
                    if (hashRateUnit === 'MH/s') hashRateUnit = 'GH/s'
                    if (hashRateUnit === 'kH/s') hashRateUnit = 'MH/s'
                    if (hashRateUnit === 'h/s') hashRateUnit = 'kH/s'
                    if (hashRateUnit === 'sol/s') hashRateUnit = 'ksol/s'
                    hashRate = hashRate / 1000
                  }
                  hashRate = +hashRate.toFixed(2)
                  let hashRateDual = card.hashCardDual > 0 ? +card.hashCardDual : 0
                  let hashRateUnitDual = props.worker.unitDual ? props.worker.unitDual : 'MH/s'
                  while (hashRateDual > 1000) {
                    if (hashRateUnitDual === 'MH/s') hashRateUnitDual = 'GH/s'
                    if (hashRateUnitDual === 'kH/s') hashRateUnitDual = 'MH/s'
                    if (hashRateUnitDual === 'h/s') hashRateUnitDual = 'kH/s'
                    if (hashRateUnitDual === 'sol/s') hashRateUnitDual = 'ksol/s'
                    hashRateDual = hashRateDual / 1000
                  }
                  hashRateDual = +hashRateDual.toFixed(2)
                  let hashRateDualString = ''
                  if (hashRateDual > 0) {
                    hashRateDualString = hashRateDual + ' ' + hashRateUnitDual
                    if (hashRateUnitDual === 'MH/s') {
                      props.worker.hashRateValueDual += hashRateDual
                    } else if (hashRateUnitDual === 'h/s') {
                      props.worker.hashRateValueDual += hashRateDual / 1000000
                    } else if (hashRateUnitDual === 'kH/s') {
                      props.worker.hashRateValueDual += hashRateDual / 1000
                    } else if (hashRateUnitDual === 'GH/s') {
                      props.worker.hashRateValueDual += hashRateDual
                    }
                  }
                  card.hashCardString = hashRate + ' ' + hashRateUnit
                  card.hashCardStringDual = ''
                  if (hashRateDualString.length > 0) card.hashCardStringDual = hashRateDualString
                }

                return (
                  <MobileCard
                    worker={props.worker}
                    card={card}
                    removeCard={removeCard}
                    offMining={offMining}
                    key={card.id + 'mobile'}
                  />
                )
              })}
            <Row className='worker-information d-md-none'>
              <Col xs={6} className='worker-information__block'>
                <h4>
                  {t('pages.workers.workerInfo')}:{' '}
                  {props.worker.reboot && Date.now() - new Date(props.worker.updateAt).getTime() < 90000 ? (
                    <h6 className='attention-status' style={{ display: 'inline' }}>
                      Rebooting
                    </h6>
                  ) : (
                    ''
                  )}
                </h4>
                <div className='worker-information__row'>
                  <span>
                    <b> • {t('pages.workers.workerID')}: </b>
                    {props.worker.id ? props.worker.id : props.newWorker.id}
                  </span>
                  <span>
                    <b> • {t('pages.workers.OSVer')}: </b>
                    <b
                      className={
                        props.worker.osVersion === 'AMOS v0.1.6'
                          ? 'red'
                          : props.worker.osVersion === 'AMOS v0.1.7'
                          ? 'red'
                          : props.worker.osVersion === 'AMOS v0.1.7a'
                          ? 'red'
                          : props.worker.osVersion === 'AMOS v0.1.7b'
                          ? 'red'
                          : props.worker.osVersion === 'AMOS v0.1.8'
                          ? 'yellow'
                          : props.worker.osVersion === 'AMOS v0.1.9'
                          ? 'green'
                          : props.worker.osVersion === 'AMOS v0.1.10'
                          ? 'green'
                          : ''
                      }
                    >
                      {props.worker.osVersion}
                    </b>
                  </span>
                  <span>
                    <b> • {t('pages.workers.driverVersion')}: </b>
                    {props.worker.driverVersion}
                  </span>
                  <span>
                    <b> • {t('pages.workers.kernel')}: </b>
                    {props.worker.osKernel}
                  </span>
                  <span>
                    <b> • {t('pages.workers.remoteIP')}: </b>
                    {props.worker.remoteIp}
                  </span>
                  <span>
                    <b> • {t('pages.workers.localIP')}: </b>
                    {props.worker.ip}
                    <button
                      className={`copy-btn
                  ${isLocalIpCopied ? 'copy-btn_active' : ''}`}
                      tooltip={isLocalIpCopied ? t('pages.workers.localIpCopied') : t('pages.workers.copyLocalIP')}
                      onClick={() => copyData(props.worker.ip)}
                      onBlur={() => setIsLocalIpCopied(false)}
                    >
                      {isLocalIpCopied ? t('pages.workers.copied') : t('pages.workers.copy')}
                    </button>
                  </span>
                  <span>
                    <b> • {t('pages.workers.MACAddress')}: </b>
                    {props.worker.rigMac}
                    <button
                      className={`copy-btn
                  ${isMACCopied ? 'copy-btn_active' : ''}`}
                      tooltip={isMACCopied ? t('pages.workers.MACCopied') : t('pages.workers.copyMAC')}
                      onClick={() => copyData(props.worker.rigMac)}
                      onBlur={() => setIsMACCopied(false)}
                    >
                      {isMACCopied ? t('pages.workers.copied') : t('pages.workers.copy')}
                    </button>
                  </span>
                </div>
              </Col>

              <Col xs={6} className='worker-information__block'>
                <h4>{t('pages.workers.workerHW')}:</h4>
                <span>
                  <b> • {t('pages.workers.motherboard')}: </b>
                  {props.worker.mb}
                </span>
                <span>
                  <b> • {t('pages.workers.RAM')}: </b>
                  {props.worker.ram}
                </span>
                <span>
                  <b> • {t('pages.workers.CPU')}: </b>
                  {props.worker.cpu}
                </span>
                <span>
                  <b> • {t('pages.workers.diskModel')}: </b>
                  {props.worker.disk}
                </span>
                <span>
                  <b> • {t('pages.workers.activePreset')}: </b>
                  {props.worker.miningPreset && <span>{props.worker.miningPreset.name}</span>}
                </span>
              </Col>
            </Row>
          </div>
          <table className='table table-striped table-bordered worker-tab d-none d-md-table'>
            <thead className='thead-light text-center'>
              <tr>
                <th>{t('pages.workers.GPU')}</th>
                <th>{t('pages.workers.clocks')}</th>
                <th>{t('pages.workers.FAN')}</th>
                <th>{t('pages.workers.temp')}</th>
                <th>{t('pages.workers.hashrate')}</th>
                <th>{t('pages.workers.power')}</th>
                <th>{t('pages.workers.shares')}</th>
                <th>{t('pages.workers.efficiency')}</th>
                <th>{t('pages.workers.status')}</th>
                <th>{t('pages.workers.action')}</th>
              </tr>
            </thead>
            <tbody>
              {props.worker.videoCards &&
                workerCards.map((card, indx) => {
                  if (indx === 0) {
                    props.worker.hashRateValue = 0
                    props.worker.hashRateValueDual = 0
                  }

                  const checkTime = nowDate - new Date(card.updatedAt).getTime()

                  card.hashCardString = 0
                  if (card.detected) {
                    let hashRate = card.hashCard > 0 ? +card.hashCard : 0

                    let hashRateUnit = props.worker.unit ? props.worker.unit : 'MH/s'
                    while (hashRate > 1000) {
                      if (hashRateUnit === 'MH/s') {
                        hashRateUnit = 'GH/s'
                      }
                      if (hashRateUnit === 'kH/s') {
                        hashRateUnit = 'MH/s'
                      }
                      if (hashRateUnit === 'h/s') {
                        hashRateUnit = 'kH/s'
                      }
                      if (hashRateUnit === 'sol/s') {
                        hashRateUnit = 'ksol/s'
                      }
                      hashRate = hashRate / 1000
                    }
                    hashRate = +hashRate.toFixed(2)
                    props.worker.hashRateValue += card.hashCard > 0 ? +card.hashCard : 0
                    let hashRateDual = card.hashCardDual > 0 ? +card.hashCardDual : 0
                    let hashRateUnitDual = props.worker.unitDual ? props.worker.unitDual : 'MH/s'
                    while (hashRateDual > 1000) {
                      if (hashRateUnitDual === 'MH/s') {
                        hashRateUnitDual = 'GH/s'
                      }
                      if (hashRateUnitDual === 'kH/s') {
                        hashRateUnitDual = 'MH/s'
                      }
                      if (hashRateUnitDual === 'h/s') {
                        hashRateUnitDual = 'kH/s'
                      }
                      if (hashRateUnitDual === 'sol/s') {
                        hashRateUnitDual = 'ksol/s'
                      }
                      hashRateDual = hashRateDual / 1000
                    }
                    hashRateDual = +hashRateDual.toFixed(2)
                    let hashRateDualString = ''
                    if (hashRateDual > 0) {
                      hashRateDualString = hashRateDual + ' ' + hashRateUnitDual
                      if (hashRateUnitDual === 'MH/s') {
                        props.worker.hashRateValueDual += hashRateDual
                      } else if (hashRateUnitDual === 'kH/s') {
                        props.worker.hashRateValueDual += hashRateDual / 1000
                      } else if (hashRateUnitDual === 'GH/s') {
                        props.worker.hashRateValueDual += hashRateDual
                      }
                    }
                    card.hashCardString = hashRate + ' ' + hashRateUnit
                    card.hashCardStringDual = ''
                    if (hashRateDualString.length > 0) card.hashCardStringDual = hashRateDualString
                  }

                  return (
                    <tr key={indx}>
                      <td className='card__info'>
                        <div className='card__num'>
                          {t('pages.workers.GPU')}
                          {card.detected === false || checkTime > 30000 ? '' : card.cardNum}
                          <p className='card_slot'>{card.cardSlot ? card.cardSlot.substring(5) : ''}</p>
                        </div>
                        <div className={isMobile ? 'card__info-data' : ''}>
                          <span
                            className={`card__name
                              ${card.cardType === 'amd' ? 'card__name_amd' : ''}`}
                          >
                            {card.gpuName}{' '}
                          </span>
                          {card.fwInfo && (
                            <>
                              {' • '}
                              {card.fwInfo}
                            </>
                          )}
                          {card.vendorName && (
                            <span className='card__vendor'>
                              {' • '}
                              {card.vendorName} {!isMobile && <br />}
                              <>{`${!card.vram.includes('M') ? card.vram + ' Mb' : card.vram}`}</>
                            </span>
                          )}

                          {card.cardType === 'nvidia' && card.DefPwrLimit && (
                            <span className='card__power'>
                              {' • '}
                              {card.nvGpuPwrMin}W / {card.DefPwrLimit}W / {card.nvGpuPwrMax}W
                            </span>
                          )}
                          {card.memInfo && <span className='card__memType'> • {card.memInfo}</span>}
                          {card.memType && <span className='card__memType'> {card.memType}</span>}
                          {!props.worker.active || card.detected === false || checkTime > 30000 ? (
                            <span className='card__off'> (OFFLINE)</span>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>

                      <td style={props.worker.reboot ? { color: '#757574' } : {}}>
                        {props.worker.active && card.cardDetected && (
                          <>
                            {isMobile && <br />}
                            {`${card.gpuClockStat > 0 ? card.gpuClockStat : ''}${
                              card.memClockStat > 0 ? `/${card.memClockStat}` : ''
                            }`}
                          </>
                        )}
                        {props.worker.active &&
                          card.cardDetected &&
                          card.hashCard > 0 &&
                          (!card.gpuClockStat || +card.gpuClockStat === 0) &&
                          (!card.fanInfo || +card.fanInfo === 0) && (
                            <span className='red' tooltip={t('pages.workers.driverError')}>
                              d/e
                            </span>
                          )}
                      </td>

                      <td style={props.worker.reboot ? { color: '#757574' } : {}}>
                        {props.worker.active && card.cardDetected && card.fanInfo > 0 && (
                          <span tooltip={card.currentSpeed && +card.currentSpeed > 0 ? card.currentSpeed + 'rpm' : ''}>
                            {card.fanInfo}
                          </span>
                        )}
                        {props.worker.active &&
                          card.hashCard > 0 &&
                          (!card.fanInfo || +card.fanInfo === 0) &&
                          (!card.gpuClockStat || +card.gpuClockStat === 0) && (
                            <span className='red' tooltip={t('pages.workers.driverError')}>
                              d/e
                            </span>
                          )}
                      </td>

                      <td
                        className={card.highTemp ? 'orange' : card.critTemp ? 'red' : ''}
                        tooltip={
                          card.highTemp
                            ? t('pages.workers.highTemperature')
                            : card.critTemp
                            ? t('pages.workers.criticalTemperature')
                            : t('pages.workers.normalTemperature')
                        }
                        style={props.worker.reboot ? { color: '#757574' } : {}}
                      >
                        {props.worker.active && card.cardDetected && (
                          <>
                            {card.gpuTemp}
                            {card.memTemp ? ` / ${card.memTemp}` : ''}
                          </>
                        )}
                      </td>

                      <td style={props.worker.reboot ? { color: '#757574' } : {}}>
                        {props.worker.active &&
                        card.cardDetected &&
                        props.worker.gpuTotalSpeed > 0 &&
                        +card.hashCardString[0] > 0
                          ? card.hashCardString
                          : ''}
                        {props.worker.active &&
                          card.cardDetected &&
                          props.worker.gpuTotalSpeedDual > 0 &&
                          card.hashCardStringDual &&
                          ' / ' + card.hashCardStringDual}
                      </td>

                      <td style={props.worker.reboot ? { color: '#757574' } : {}}>
                        {props.worker.active && card.cardDetected && card.powerInfo && <>{card.powerInfo}</>}
                      </td>

                      <td style={props.worker.reboot ? { color: '#757574' } : {}}>
                        {props.worker.active && card.cardDetected && (
                          <>
                            <span tooltip={t('pages.workers.accepted')}>
                              {card.blocksNumber > 0 ? card.blocksNumber : ''}
                            </span>
                            {card.badBlocsNumber > 0 && (
                              <span>
                                /
                                <span tooltip={t('pages.workers.rejected')} className='orange'>
                                  {card.badBlocsNumber}
                                </span>
                              </span>
                            )}
                            {card.invalidShares > 0 && (
                              <span>
                                /
                                <span tooltip={t('pages.workers.invalid')} className='red'>
                                  {card.invalidShares}
                                </span>
                              </span>
                            )}
                            {card.blocksNumberDual ? (
                              <>
                                <span tooltip={t('pages.workers.accepted')}>
                                  {card.blocksNumberDual > 0 ? <>{` / ${card.blocksNumberDual}`}</> : ''}
                                </span>
                                {card.badBlocsNumberDual > 0 && (
                                  <span>
                                    /
                                    <span tooltip={t('pages.workers.rejected')} className='orange'>
                                      {card.badBlocsNumberDual}
                                    </span>
                                  </span>
                                )}

                                {card.invalidSharesDual > 0 && (
                                  <span>
                                    /
                                    <span tooltip={t('pages.workers.invalid')} className='red'>
                                      {card.invalidSharesDual}
                                    </span>
                                  </span>
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </td>

                      <td>
                        {props.worker.active && card.cardDetected && card.blocksNumber > 0 && (
                          <>
                            {card.blocksNumberDual ? (
                              <>
                                {card.cardEffiency}%{` / ${card.cardEffiencyDual}`}%
                              </>
                            ) : (
                              <>{card.cardEffiency}%</>
                            )}
                          </>
                        )}
                        {+card.blocksNumber === 0 && (+card.invalidShares > 0 || +card.badBlocsNumber > 14) && (
                          <>
                            <span tooltip={t('pages.workers.notEfficient')} className='red'>
                              0
                            </span>
                          </>
                        )}
                        {card.blocksNumberDual === 0 && card.invalidSharesDual > 0 && (
                          <>
                            <span tooltip={t('pages.workers.notEfficient')} className='red'>
                              0
                            </span>
                          </>
                        )}
                      </td>

                      <td>
                        {props.worker.active && card.cardDetected && (
                          <div className={'worker_statuses'}>
                            {/* //////////////////////////////////////////////////////// card statuses */}
                            {card?.lowHash === 2 && (
                              <span className='rejected' tooltip={t('pages.workers.lowHashrate')}>
                                <i className='far fa-chart-line-down' style={{ color: 'red' }}></i>
                              </span>
                            )}

                            {card?.lowHash === 1 && (
                              <span className='rejected' tooltip={t('pages.workers.lowHashrate')}>
                                <i className='far fa-chart-line-down' style={{ color: 'yellow' }}></i>
                              </span>
                            )}

                            {card.newCard && (
                              <span className='rejected' tooltip={t('pages.workers.noOverclocking')}>
                                <i className='fas fa-sliders-h' style={{ color: 'yellow' }}></i>
                              </span>
                            )}

                            {+card.highTemp ? (
                              <span className='rejected card__status' tooltip={t('pages.workers.highTemperature')}>
                                <i className='fas fa-temperature-high' style={{ color: 'yellow' }}></i>
                                {/* <small>{card.gpuTemp}°</small> */}
                              </span>
                            ) : (
                              ''
                            )}
                            {+card.critTemp ? (
                              <span className='rejected card__status' tooltip={t('pages.workers.criticalTemperature')}>
                                <i className='fas fa-burn' style={{ color: 'red' }}></i>
                                {/* <small>{card.gpuTemp}°</small> */}
                              </span>
                            ) : (
                              ''
                            )}

                            {/* //////////////////////////////////////////////////// end card statuses */}
                          </div>
                        )}
                      </td>

                      <td>
                        <div className={'actions__items_card'}>
                          {/* <div
                            className={'actions__item actions__item_card'}
                            tooltip="Card Information"
                          >
                            <i className="fas fa-chart-bar"></i>
                          </div> */}
                          <div
                            className={'actions__item actions__item_card'}
                            tooltip={t('pages.workers.deleteCard')}
                            onClick={() => removeCard(card.id)}
                          >
                            <i className='fas fa-trash'></i>
                          </div>
                          {card.offMining ? (
                            <div
                              className={'actions__item actions__item_card'}
                              tooltip={t('pages.workers.includeCard')}
                              onClick={() => offMining(card.id, false)}
                            >
                              <i className='fas fa-long-arrow-alt-up icon-md'></i>
                            </div>
                          ) : (
                            <div
                              className={'actions__item actions__item_card'}
                              tooltip={t('pages.workers.excludeCard')}
                              onClick={() => offMining(card.id, true)}
                            >
                              <i className='fas fa-long-arrow-alt-down icon-md'></i>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                })}
              {props.worker.active && (
                <tr className='card__info-total'>
                  <td>{t('pages.workers.total')}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    {totalHashRate(props.worker.hashRateValue, props.worker.unit)}
                    {!!props.worker.hashRateValueDual && (
                      <> / {totalHashRate(props.worker.hashRateValueDual, props.worker.unitDual)}</>
                    )}
                  </td>
                  <td>{totalPower > 0 ? totalPower : ''}</td>
                  <td>
                    <span tooltip={t('pages.workers.accepted')}>{sharesSum}</span>
                    {rejectesSum > 0 && (
                      <span>
                        /
                        <span tooltip={t('pages.workers.rejected')} className='orange'>
                          {rejectesSum}
                        </span>
                      </span>
                    )}
                    {invalidSum > 0 && (
                      <span>
                        /
                        <span tooltip={t('pages.workers.invalid')} className='red'>
                          {invalidSum}
                        </span>
                      </span>
                    )}

                    {sharesSumDual ? (
                      <>
                        <span tooltip={t('pages.workers.accepted')}>{` / ${sharesSumDual}`}</span>
                        {rejectesSumDual > 0 && (
                          <span>
                            /
                            <span tooltip={t('pages.workers.rejected')} className='orange'>
                              {rejectesSumDual}
                            </span>
                          </span>
                        )}
                        {invalidSumDual > 0 && (
                          <span>
                            /
                            <span tooltip={t('pages.workers.invalid')} className='red'>
                              {invalidSumDual}
                            </span>
                          </span>
                        )}
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {totalEff > 0 ? totalEff + '%' : ''}
                    {totalEffDual > 0 ? <>{` / ${totalEffDual}%`}</> : ''}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className='worker-information d-none d-md-flex'>
        <Col md={8} xs={12} className='worker-information__block'>
          <h4 style={{ display: 'inline' }}>
            {t('pages.workers.workerInfo')}:{' '}
            {props.worker.reboot && Date.now() - new Date(props.worker.updateAt).getTime() < 90000 ? (
              <h6 className='attention-status' style={{ display: 'inline' }}>
                Rebooting
              </h6>
            ) : (
              ''
            )}
          </h4>
          <div className='worker-information__row'>
            <div className='worker-information__col'>
              <span>
                <b> • {t('pages.workers.workerID')}: </b>
                {props.worker.id ? props.worker.id : props.newWorker.id}
              </span>
              <span>
                <b> • {t('pages.workers.description')}: </b>
                {props.worker.description}
              </span>
              <span>
                <b> • {t('pages.workers.OSVer')}: </b>
                <b
                  className={
                    props.worker.osVersion === 'AMOS v0.1.6'
                      ? 'red'
                      : props.worker.osVersion === 'AMOS v0.1.7'
                      ? 'red'
                      : props.worker.osVersion === 'AMOS v0.1.7a'
                      ? 'red'
                      : props.worker.osVersion === 'AMOS v0.1.7b'
                      ? 'red'
                      : props.worker.osVersion === 'AMOS v0.1.8'
                      ? 'red'
                      : props.worker.osVersion === 'AMOS v0.1.9'
                      ? 'red'
                      : props.worker.osVersion === 'AMOS v0.1.10'
                      ? 'red'
                      : props.worker.osVersion === 'AMOS v0.1.11'
                      ? 'yellow'
                      : props.worker.osVersion === 'AMOS v0.1.12'
                      ? 'green'
                      : ''
                  }
                >
                  {props.worker.osVersion}
                </b>
              </span>
              <span>
                <b> • {t('pages.workers.driverVersion')}: </b>
                {props.worker.driverVersion}
              </span>
            </div>

            <div className='worker-information__col'>
              <span>
                <b> • {t('pages.workers.kernel')}: </b>
                {props.worker.osKernel}
              </span>
              <span>
                <b> • {t('pages.workers.remoteIP')}: </b>
                {props.worker.remoteIp}
              </span>
              <span>
                <b> • {t('pages.workers.localIP')}: </b>
                {props.worker.ip}
                <button
                  className={`copy-btn
                  ${isLocalIpCopied ? 'copy-btn_active' : ''}`}
                  tooltip={isLocalIpCopied ? t('pages.workers.localIpCopied') : t('pages.workers.copyLocalIP')}
                  onClick={() => copyData(props.worker.ip)}
                  onBlur={() => setIsLocalIpCopied(false)}
                >
                  {isLocalIpCopied ? t('pages.workers.copied') : t('pages.workers.copy')}
                </button>
              </span>
              <span>
                <b> • {t('pages.workers.MACAddress')}: </b>
                {props.worker.rigMac}
                <button
                  className={`copy-btn
                  ${isMACCopied ? 'copy-btn_active' : ''}`}
                  tooltip={isMACCopied ? t('pages.workers.MACCopied') : t('pages.workers.copyMAC')}
                  onClick={() => copyData(props.worker.rigMac)}
                  onBlur={() => setIsMACCopied(false)}
                >
                  {isMACCopied ? t('pages.workers.copied') : t('pages.workers.copy')}
                </button>
              </span>
            </div>
            <div className='worker-information__col'>
              <span>
                <b> • {t('pages.workers.loadAverage')}: </b>
                {props.worker.average && props.worker.active && (
                  <b
                    className={
                      loadAverage >= 3
                        ? 'red'
                        : loadAverage >= 2 && loadAverage < 3
                        ? 'yellow'
                        : loadAverage < 2
                        ? 'green'
                        : ''
                    }
                  >
                    {props.worker.average}
                  </b>
                )}
              </span>
              <span>
                <b> • {t('pages.workers.activePreset')}: </b>
                {props.worker.miningPreset && <span>{props.worker.miningPreset.name}</span>}
              </span>
              <span>
                <b> • {t('pages.workers.miner')}: </b>
                {props.worker.active && props.worker.downloadMiner ? (
                  <>
                    {props.worker.miner}
                    <i className='fa fa-spinner fa-pulse fa-1x fa-fw' style={{ color: 'green', margin: '5px' }}></i>
                  </>
                ) : props.worker.secondMiner ? (
                  `${props.worker.miner} / ${props.worker.secondMiner}`
                ) : (
                  props.worker.miner
                )}
              </span>
              <span>
                <b> • {props.worker.coinDual ? t('pages.workers.coins') : t('pages.workers.coin')}: </b>
                {props.worker.coin} {props.worker.coinDual}
              </span>
            </div>
          </div>
        </Col>

        <Col md={4} xs={12} className='worker-information__block'>
          <h4>{t('pages.workers.workerHW')}:</h4>
          <span>
            <b> • {t('pages.workers.motherboard')}: </b>
            {props.worker.mb}
          </span>
          <br />
          <span>
            <b> • {t('pages.workers.RAM')}: </b>
            {props.worker.ram}
          </span>
          <br />
          <span>
            <b> • {t('pages.workers.CPU')}: </b>
            {props.worker.cpu}
          </span>
          <br />
          <span>
            <b> • {t('pages.workers.diskModel')}: </b>
            {props.worker.disk}
          </span>
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = store => ({
  averageHashList: store.presets.averageHashList,
  coinsList: store.presets.coinsList,
  rigStatList: store.rigs.rigStatList,
})

const mapDispatchToProps = {
  getRig: actions.getRig,
  deleteRig: actions.deleteRig,
  deleteCard: actions.deleteCard,
  offMiningCard: actions.offMiningCard,
  setRigMinerAction: actions.setRigMinerAction,
  resetOrPowerOfWorker: actions.resetOrPowerOfWorker,
  setRigStats: actions.setRigStats,
  setAverageHashList: miningPresetActions.setAverageHashList,
  delayRigUpdate: actions.delayRigUpdate,
  clearRigFullInfo: actions.clearRigFullInfo,
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics)
